import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import moment from 'moment'

function InventorySchema({ menuItems }) {
	let schemaList = menuItems.map((item) => {
		const { productName, category, prices = [] } = item
		return {
			'@context': 'http://www.schema.org',
			'@type': 'Product',
			name: productName,
			category,
			offers: prices.map((item) => {
				return {
					'@type': 'Offer',
					availability: 'http://schema.org/InStock',
					price: item.price,
					name: item.uom,
					eligibleQuantity: item.uom,
					priceCurrency: 'USD',
				}
			}),
		}
	})

	return (
		<>
			{schemaList.map((schema, index) => (
				<Helmet key={`product_schema_${index}`}>
					<script type="application/ld+json">{JSON.stringify({ ...schema })}</script>
				</Helmet>
			))}
		</>
	)
}

InventorySchema.propTypes = {
	menuItems: PropTypes.array,
}

export default InventorySchema
